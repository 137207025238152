import React from 'react'
import Layout from '../../layouts'
import { graphql } from 'gatsby'

import MetaHeader from '../../components/MetaHeader'
import WIVPage from '../../components/WIVPage'

const WomenInVenture = ({ data }) => {
  const {
    allReports: { edges: reports },
    mainPage: {
      edges: [{ node: pageData }],
    },
  } = data

  const {
    pageHeader: {
      heading,
      image,
      metaTitle,
      metaDescription,
      metaKeywords,
      metaSiteUrl,
      metaTwitter,
    },
  } = pageData

  return (
    <>
      <MetaHeader
        title={metaTitle || heading}
        image={'http:' + image?.file?.url}
        siteUrl={metaSiteUrl}
        description={metaDescription?.metaDescription}
        keywords={metaKeywords?.metaKeywords}
        twitter={metaTwitter}
      />
      <Layout>
        <WIVPage pageData={pageData} reports={reports} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allReports: allContentfulWomenInVenture(
      sort: { fields: year, order: DESC }
    ) {
      edges {
        node {
          year
        }
      }
    }
    mainPage: allContentfulWomenInVenture(
      sort: { fields: year, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          pageHeader {
            heading
            subheading
            image {
              title
              file {
                url
              }
            }
            metaTitle
            metaDescription {
              metaDescription
            }
            metaKeywords {
              metaKeywords
            }
            metaSiteUrl
            metaTwitter
          }
          ctaText
          abstractRtf {
            childContentfulRichText {
              html
            }
          }
          bodyRtf {
            childContentfulRichText {
              html
            }
          }
          documents {
            id
            title
            link
            linkNewsletter
          }
        }
      }
    }
  }
`

export default WomenInVenture
